import { useEffect } from 'react'

import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded'

import { createClient } from '@supabase/supabase-js'
import LoadingButton from '@mui/lab/LoadingButton'
import { Badge, IconButton, Popover, Typography } from '@mui/material'

import { useMutation } from '@redwoodjs/web'

import Loading from 'src/components/Loading'
import Button from 'src/components/MUI/Button'
import useQuery from 'src/hooks/useQuery'

import Notification from './Notification'

import useUserId from 'src/hooks/useUserId'
import { toast } from '@redwoodjs/web/toast'

const GET_NOTIFICATIONS = gql`
  query GetNotifications {
    notifications {
      id
      message
      read
      createdAt
      actionName
      actionPerformed
      link
    }
  }
`

const MARK_ALL_NOTIFICATIONS_AS_READ = gql`
  mutation MarkAllNotificationsAsRead {
    markAllNotificationsAsRead {
      id
      read
    }
  }
`

const supabase = createClient(
  process.env.SUPABASE_URL,
  process.env.SUPABASE_ANON_KEY
)

const NotificationPopover = () => {
  const { id: userId, hasLoaded: userIdHasLoaded } = useUserId()
  useEffect(() => {
    if (!userIdHasLoaded || !userId) return
    const channel = supabase
      .channel('realtime notifications')
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'Notification',
          filter: `userId=eq.${userId}`,
        },
        (payload) => {
          toast(payload.new.message)
          refetch()
        }
      )
      .subscribe()
    return () => {
      supabase.removeChannel(channel)
    }
  }, [supabase, userIdHasLoaded])

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const [markAllNotificationsAsRead] = useMutation(
    MARK_ALL_NOTIFICATIONS_AS_READ
  )

  const { data, hasLoaded, refetch } = useQuery(GET_NOTIFICATIONS)
  const notifications = data?.notifications ?? []


  const unreadCount = notifications.filter((n) => !n.read).length

  const handleNotificationsClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleNotificationsClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        aria-label={`show ${unreadCount} new notifications`}
        color="inherit"
        onClick={handleNotificationsClick}
      >
        <Badge badgeContent={unreadCount} color="error">
          <NotificationsNoneRoundedIcon />
        </Badge>
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleNotificationsClose}
        classes={{
          paper:
            'flex flex-col rounded-md w-45 max-h-45 mt-0.5 overflow-hidden',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="m-1 flex items-center justify-between">
          <Typography variant="h6">Notifications</Typography>
          <Button
            size="small"
            variant="text"
            onClick={() => markAllNotificationsAsRead()}
          >
            Mark all as read
          </Button>
        </div>
        {!hasLoaded && (
          <div className="m-4 flex h-full items-center justify-center">
            <Loading />
          </div>
        )}
        {hasLoaded && notifications.length === 0 && (
          // handle no notifications
          <div className="flex h-full items-center justify-center">
            <Typography variant="subtitle2" className="my-4">
              No new notifications
            </Typography>
          </div>
        )}
        <div className="h-full overflow-scroll px-1">
          {notifications?.map((notification) => (
            <Notification
              key={notification.id}
              notification={notification}
              handleClose={handleNotificationsClose}
            />
          ))}
        </div>
      </Popover>
    </>
  )
}

export default NotificationPopover
